import { Course } from "./interfaces";
import { Image } from "components/Image/Image";
import { IconStar } from "components/Icon/IconStar";
import { formatRating, numberWithCommas } from "lib/helper";
import Button from "components/Button";
import useTranslation from "next-translate/useTranslation";
import { LogoChip } from "components/Chip/LogoChip";
import Link from "next/link";
import { useAuthStore } from "modules/Auth/authStore";
import { useIsDesktop } from "lib/device";
import IconChevronRight from "components/Icon/IconChevronRight";
import { useEffect, useState } from "react";
import { IconPlayCircle } from "components/Icon/IconPlayCircle";

export const CardTenantMyClass: React.FC<
  Course & { isHomePage?: boolean; height?: number }
> = (props) => {
  const { height = 120, isHomePage = false } = props;
  const hasUserPaid: boolean = (props as any).is_paid;
  const hasCertificate: boolean = (props as any).has_certificate;
  const hasPaywall: boolean =
    (props as any).is_paywall_enabled === undefined
      ? true
      : (props as any).is_paywall_enabled;
  const isCourseFree: boolean =
    props.original_price === 0 || props.discounted_price === 0;
  const hasFinished = props.enrollment_progress?.finished;
  const isNumber = typeof props.enrollment_progress?.finished === "number";
  const totalLesson = props.enrollment_progress?.total || 0;
  const institutionName =
    //@ts-ignore
    props.institution?.name || props.institution?.split("||")[0] || "";

  const isCourseFinished = isNumber
    ? hasFinished === props.enrollment_progress?.total
    : 0;

  const { t } = useTranslation("course");
  const isDesktop = useIsDesktop();

  const currentUser = useAuthStore((state) => state.currentUser);
  const isEnterprisePlus =
    currentUser?.tenant !== undefined &&
    [
      "Enterprise Plus",
      "Pro Plus",
      `Pro Custom (${currentUser?.referral})`,
    ].includes(currentUser?.tenant?.plan?.name);
  // const cardHeight = isDesktop ? height : 116;
  const [cardHeight, setCardHeight] = useState(height);
  useEffect(() => {
    const widthWindow = typeof window !== "undefined" && window.innerWidth;
    const window20 = 0.2 * widthWindow;
    if (window20 < cardHeight) {
      setCardHeight(window20);
    }
  }, [window]);

  return (
    <div
      className={`rounded-8 overflow-hidden min-w-[270px] w-[31vw] flex h-${cardHeight} cursor-pointer border border-neutral20_to_black`}
    >
      <div
        className={`w-${cardHeight} max-w-[305px] relative overflow-hidden flex-shrink-0 bg-white`}
      >
        <Image
          src={props.image_cover_url}
          height={cardHeight}
          width={cardHeight}
          className={`h-${cardHeight} w-full object-cover h-full`}
        />

        <div className={"absolute top-0 grid-cols-1 w-full h-full"}>
          <div className="h-[100%] flex justify-center items-center">
            {/* <img
              src={"/images/icon-play-grey.svg"}
              alt="playGrey"
              width={45}
              height={45}
            /> */}
            <IconPlayCircle size={45} classes="m-auto" isBgCustomized />
          </div>
        </div>

        {isCourseFinished ? (
          <div className="absolute bottom-8 right-8">
            <div className="rounded-16 bg-green-10 text-green-90 px-8 py-4 text-c1 font-medium flex justify-center items-center">
              Completed
            </div>
          </div>
        ) : null}
      </div>

      <div className="bg-white_to_dark10 w-full text-neutral-80 flex flex-col justify-between px-10 py-10">
        <div>
          <div className="text-b2 font-light text-neutral80_to_dark10 md-max:hidden">
            {institutionName}
          </div>
          <h3 className="text-b1 font-medium max-w-full line-clamp-1 text-neutral80_to_dark10">
            {props.name}
          </h3>
        </div>

        {/* {hasPaywall &&
        !hasUserPaid && isCourseFinished && (isEnterprisePlus ||
        hasCertificate) */}
        {hasPaywall &&
        !hasUserPaid &&
        isCourseFinished &&
        // (isEnterprisePlus || hasCertificate) ? (
        // remove validate OR
        isEnterprisePlus &&
        hasCertificate ? (
          <Button
            variant="secondary_white"
            size="medium"
            isFull
            className="px-0"
          >
            {t("downloadCertif")}
          </Button>
        ) : isNumber ? (
          <div className="flex items-center mb-4">
            {/* <div className="flex-1 h-6  bg-purple-05 rounded-full overflow-hidden relative">
              <div
                className={`rounded-full h-full ${
                  isCourseFinished ? "bg-green-50" : "bg-purple-50"
                }`}
                style={{
                  width: (hasFinished / totalLesson) * 100 + "%",
                }}
              ></div>
            </div> */}

            <div
              className={`flex-1 h-6 bg-purple10_to_field_input_disable relative overflow-hidden rounded-full`}
            >
              <div
                className="h-full absolute left-0 top-0 rounded-full bg-purple50_to_white"
                style={{
                  width: (hasFinished / totalLesson) * 100 + "%",
                  transition: "width 0.5s ease-in-out",
                }}
              ></div>
            </div>

            <div
              className={`text-b2 font-light ${
                isCourseFinished
                  ? "text-green50_to_white"
                  : "text-purple50_to_white"
              }`}
            >
              {isCourseFinished ? (
                <p>Selesai</p>
              ) : (
                <>
                  <p className="ml-8 md-max:hidden">
                    {/* {hasFinished} dari{" "}
                    {props.enrollment_progress?.total} */}
                    {((hasFinished / props.enrollment_progress?.total) * 100)
                      .toString()
                      .slice(0, 4) + "%"}
                  </p>
                  <p className="ml-8 md-max:block hidden">
                    {hasFinished} dari {props.enrollment_progress?.total}
                  </p>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="flex gap-x-8 justify-start items-center text-c1 font-light">
            {typeof props?.review_summary?.star_avg === "number" &&
            //@ts-ignore
            props?.review_summary?.star_avg !== 0 ? (
              <>
                <div className="flex items-center justify-center">
                  <IconStar size={14} fill color="#EFB443" />
                  <div className="ml-2 text-c1">
                    {formatRating(props?.review_summary?.star_avg.toFixed(1))}
                  </div>
                </div>
                |
              </>
            ) : null}
            {props.total_learners !== 0 && (
              <div>{`${numberWithCommas(props.total_learners)} pelajar`}</div>
            )}
          </div>
        )}

        {isHomePage && (
          <div className=" md-max:hidden bg-white_to_dark80 max-h-44 md:max-h-50 border border-neutral-10 shadow-md p-8 flex-1 flex justify-between items-center rounded-8">
            <div>
              <p className="text-c1 mb-4 font-medium text-purple70_to_dark10 line-clamp-1">
                <span className="text-orange-50">{`${props?.is_remaining_duration}m`}</span>{" "}
                {t("toCompleteChapter")}:
              </p>
              <p className="text-purple70_to_dark10 text-b2 font-medium line-clamp-1">
                {props.is_chapter_name}
              </p>
            </div>
            <div>
              <IconChevronRight color="#2A1060" size={16} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
