export const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("courseDB", 1);

    request.onupgradeneeded = function (event) {
      const db = (event.target as any).result;
      if (!db.objectStoreNames.contains("courses")) {
        const objectStore = db.createObjectStore("courses", {
          keyPath: "slug",
        });
        objectStore.createIndex("slug", "slug", { unique: true });
      }
    };

    request.onsuccess = function (event) {
      resolve((event.target as any).result);
    };

    request.onerror = function (event) {
      reject((event.target as any).error);
    };
  });
};

export const openVideoDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("videoDB", 1);

    request.onupgradeneeded = function (event) {
      const db = (event.target as any).result;
      if (!db.objectStoreNames.contains("videos")) {
        db.createObjectStore("videos", {
          keyPath: "slug",
        });
      }
    };

    request.onsuccess = function (event) {
      resolve((event.target as any).result);
    };

    request.onerror = function (event) {
      reject((event.target as any).error);
    };
  });
};

export const saveCourseToIndexedDB = async (
  courseSlug: string,
  currentUser: any,
  courseData: any
) => {
  try {
    const db: any = await openDatabase();
    const transaction = db.transaction("courses", "readwrite");
    const store = transaction.objectStore("courses");
    const request = store.put({
      slug: courseSlug,
      user: currentUser,
      data: courseData,
    });

    request.onsuccess = function () {
      console.log(`Course data with slug ${courseSlug} saved to IndexedDB`);
    };

    request.onerror = function (event) {
      console.error("Failed to save course data to IndexedDB", event);
    };
  } catch (error) {
    console.error("Error opening database", error);
  }
};

export const saveVideoToIndexedDB = async (
  lessonSlug: string,
  videoUrl: any
) => {
  try {
    const db: any = await openVideoDatabase();

    const response = await fetch(videoUrl);
    const blob = await response.blob();
    const videoBlob = new Blob([blob], { type: "video/mp4" });

    const transaction = db.transaction("videos", "readwrite");
    const store = transaction.objectStore("videos");

    const request = store.put({
      slug: lessonSlug,
      video: videoBlob,
    });

    request.onsuccess = function () {
      console.log(`Video with slug ${lessonSlug} saved to IndexedDB`);
    };

    request.onerror = function (event) {
      console.error("Failed to save video data to IndexedDB", event);
    };

    await new Promise<void>((resolve, reject) => {
      transaction.oncomplete = () => resolve();
      transaction.onerror = (event) => reject(event);
    });
  } catch (error) {
    console.error("Error opening database", error);
  }
};

export const getCourseFromIndexedDB = async (courseSlug: string) => {
  try {
    const db: any = await openDatabase();
    const transaction = db.transaction("courses", "readonly");
    const store = transaction.objectStore("courses");
    const request = store.get(courseSlug);

    return new Promise((resolve, reject) => {
      request.onsuccess = function (event) {
        if (request.result) {
          resolve(request.result.data);
        } else {
          reject(`No data found for slug ${courseSlug}`);
        }
      };

      request.onerror = function () {
        reject("Failed to retrieve data from IndexedDB");
      };
    });
  } catch (error) {
    console.error("Error opening database", error);
  }
};

export const getVideoFromIndexedDB = async (lessonSlug: string) => {
  try {
    const db: any = await openVideoDatabase();
    const transaction = db.transaction("videos", "readonly");
    const store = transaction.objectStore("videos");
    const request = store.get(lessonSlug);

    return new Promise(async (resolve, reject) => {
      request.onsuccess = function (event: any) {
        const request = event.target.result;

        if (request) {
          const videoUrl = URL.createObjectURL(request.video);
          resolve(videoUrl);
        } else {
          reject(`No video found for slug ${lessonSlug}`);
        }
      };

      request.onerror = function () {
        reject("Failed to retrieve video from IndexedDB");
      };

      await new Promise<void>((resolve, reject) => {
        transaction.oncomplete = () => resolve();
        transaction.onerror = (event) => reject(event);
      });
    });
  } catch (error) {
    console.error("Error accessing database", error);
  }
};

export const getAllCoursesFromIndexedDB = async () => {
  try {
    const db: any = await openDatabase();
    const transaction = db.transaction("courses", "readonly");
    const store = transaction.objectStore("courses");
    const request = store.getAll();

    return new Promise((resolve, reject) => {
      request.onsuccess = function (event) {
        resolve(event.target.result);
      };

      request.onerror = function () {
        reject("Failed to retrieve data from IndexedDB");
      };
    });
  } catch (error) {
    console.error("Error accessing database", error);
  }
};

export const deleteCourseFromIndexedDB = async (slug: string) => {
  try {
    const db: any = await openDatabase();
    const transaction = db.transaction("courses", "readwrite");
    const store = transaction.objectStore("courses");
    const request = store.delete(slug);

    return new Promise((resolve, reject) => {
      request.onsuccess = function (event) {
        resolve("Course deleted successfully");
      };

      request.onerror = function () {
        reject("Failed to delete course from IndexedDB");
      };
    });
  } catch (error) {
    console.error("Error accessing IndexedDB:", error);
  }
};

export const deleteVideoFromIndexedDB = async (slug: string) => {
  try {
    const db: any = await openVideoDatabase();
    const transaction = db.transaction("videos", "readwrite");
    const store = transaction.objectStore("videos");
    const request = store.delete(slug);

    return new Promise((resolve, reject) => {
      request.onsuccess = function (event) {
        resolve("Video deleted successfully");
      };

      request.onerror = function () {
        reject("Failed to delete video from IndexedDB");
      };
    });
  } catch (error) {
    console.error("Error accessing IndexedDB:", error);
  }
};
