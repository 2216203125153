import { useSystemStore } from "components/PageHeader/systemStore";
import React from "react";

type Props = {
  size?: number;
  color?: string;
};

const IconChevronRight: React.FC<Props> = ({
  size = 24,
  color = "#3E3851",
}) => {
  const theme = useSystemStore((state) => state.theme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.5388 11.29L9.87876 5.64001C9.7858 5.54628 9.6752 5.47188 9.55334 5.42111C9.43148 5.37035 9.30077 5.34421 9.16876 5.34421C9.03675 5.34421 8.90604 5.37035 8.78418 5.42111C8.66233 5.47188 8.55172 5.54628 8.45876 5.64001C8.27251 5.82737 8.16797 6.08082 8.16797 6.34501C8.16797 6.60919 8.27251 6.86264 8.45876 7.05001L13.4088 12.05L8.45876 17C8.27251 17.1874 8.16797 17.4408 8.16797 17.705C8.16797 17.9692 8.27251 18.2226 8.45876 18.41C8.55138 18.5045 8.66182 18.5797 8.7837 18.6312C8.90557 18.6827 9.03645 18.7095 9.16876 18.71C9.30107 18.7095 9.43195 18.6827 9.55383 18.6312C9.6757 18.5797 9.78615 18.5045 9.87876 18.41L15.5388 12.76C15.6403 12.6664 15.7213 12.5527 15.7767 12.4262C15.8321 12.2997 15.8607 12.1631 15.8607 12.025C15.8607 11.8869 15.8321 11.7503 15.7767 11.6238C15.7213 11.4973 15.6403 11.3836 15.5388 11.29Z"
        fill={theme == "dark" ? "#FFF" : color}
      />
    </svg>
  );
};

export default IconChevronRight;
